@tailwind base;

@layer base {
  html {
    @apply text-neutral-800;
  }
  html.dark {
    @apply text-neutral-50;
    @apply bg-neutral-800;
  }
}

@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: rgb(250, 250, 250);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loginBanner {
  background-size: cover;
  background-position: center;
  background-image: url("./static/loginBanner.jpg");
}